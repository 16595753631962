<template>
<section>
    <div class="page-header">
        <h3 class="page-title">
        {{title}} <span class="text-muted">{{searchResult.length}}</span>
        </h3>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <span v-if="!activePerpage">
                    <!-- n'affiche rien -->
                </span>
                <span v-else class="computer">
                    <b-form-select size="md" variant="gradient-primary" v-model="perPage" style="width:auto">
                        <b-form-select-option value="3">3</b-form-select-option>
                        <b-form-select-option value="5">5</b-form-select-option>
                        <b-form-select-option value="10">10</b-form-select-option>
                        <b-form-select-option value="10">15</b-form-select-option>
                        <b-form-select-option value="10">20</b-form-select-option>
                        <b-form-select-option value="25">25</b-form-select-option>
                        <b-form-select-option value="50">50</b-form-select-option>
                    </b-form-select>
                </span>

                <b-pagination class="separated pagination-danger pl-2 computer"
                :total-rows="rows"
                v-model="currentPage"
                :per-page="perPage"
                aria-controls="id">
                </b-pagination>
                <!-- <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search"></b-form-input> -->
            </ol>
            <ol class="breadcrumb">
                <b-form-input class="mt-2" size="md" v-if="activeSearch" v-model="search"></b-form-input>
            </ol>
        </nav>
    </div>
    <div class="row product-item-wrapper" v-if="searchResult.length>0">
        <div class="col product-item stretch-card" :class="col" v-for="(item, i) in paginateItems" :key="i"
        id="id"
        :current-page="currentPage"
        :per-page="perPage">
            
            <div class="card">
                <!-- EDIT / DELETE -->
                <label title="Editer le produit" class="badge badge-info crud edit pointer"  style="z-index:1" @click="editProduct(item)"><i class="fa fa-pencil"></i></label>
                <label title="Supprimer le produit" class="badge badge-danger crud delete pointer"  style="z-index:1" @click="$emit('deleteProduct', item)"><i class="fa fa-trash"></i></label>

                <div class="card-body">
                    <div class="product-img-outer">
                        <!-- image -->
                        <template v-if="Array.isArray(item.image) && item.image.length">
                            <div v-for="(val,key,index) of item.image" :key="index">
                                <span v-if="key == 0">
                                    <picture   >
                                        <source class="product_image"  :srcset="val.content.webpImage" type="image/webp">
                                        <img class="product_image"  :src="val.content.originalImage" alt="Card image cap">
                                    </picture>
                                </span>
                            </div>
                        </template>
                        <img v-else  class="product_image" src="https://fakeimg.pl/300x200"  alt="Card image">
                    </div>
                    <!-- détails -->
                    <p class="product-title" @click="showDetails(item)" style="cursor:pointer">{{item.name}}</p>
                    <p class="product-price">{{roundNumber(item.price * 1.2)}} €</p>
                    <ul class="product-variation" v-if="variationDetails">
                        <li><a href="javascript:void(0);"><i class="mdi mdi-weight"></i>{{item.weight}} kg</a></li>
                        <li><a href="javascript:void(0);"><i></i>{{item.stock}} restants</a></li>
                        <li><a href="javascript:void(0);"><i class="mdi mdi-glass-stange "></i>{{item.volume}} ml</a></li>
                    </ul>
                    <p class=""><i class=" mdi mdi-map-marker"></i>  {{item.shippinglocation}}</p>
                    <div class="row product-description">
                    </div>
                </div>
            </div>
        </div>
        <div class="col mobile">
            <b-pagination class="separated pagination-danger pl-2"
            :total-rows="rows"
            v-model="currentPage"
            :per-page="perPage"
            aria-controls="id">
            </b-pagination>
            <span v-if="!activePerpage">
                    <!-- n'affiche rien -->
            </span>
        </div>
    </div>
    <div v-else>
        <h4>
            {{loadingMessage}}
        </h4> 
    </div>
</section>
</template>
<script>
import { priceMixin } from '../../pages/dashboard/mixins/price'
// import priceMixin from '../../pages/mixins/price.js'
const axios=require('axios').default
export default {
    name: 'paginateCard',
    mixins:[priceMixin],
    components:{
        // addImage
    },
    data() {
        return {
            currentPage: 1
        }
    },
    props: {
        activeSearch: Boolean,
        activePerpage: Boolean,
        search: String,
        perPage: {
            default: 20
        },
        id: String,
        items: Array,
        title: String,
        col: String,
        name: String,
        price: Number,
        variationDetails: Boolean,
        loadingMessage: String
    },
    computed:{
        rows () {
            return this.items.length
        },
        searchResult() {
            return this.search ? this.items.filter(
                item => item.name.toLowerCase().includes(this.search.toLowerCase())
            )
            :this.items
        },
        paginateItems () {
            return this.searchResult.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        }
        // upperCase(name) {
        //     return name.charAt(0).toUpperCase()+ name.slice(1)
        // }
    },
    watch: {
        searchResult(value) {
            console.log('valeur de recherche dans simple card',value);
        }
    },
    methods: {
        showDetails(item) {
            const ulid= item.ulid
            this.$router.push({
                name:'catalogueDetails',
                params: { ulid }
            })
        },
        // delete
        deleteProduct(product) {
        axios.delete(`products/${product.ulid}`,{
            headers: {
            'X-AUTH-TOKEN':localStorage.getItem('token')
            }
        })
        .then(()=>{
            this.getProducts()
        }
        )
        .catch(err=>console.log(err))
        },
        //edit
        editProduct(product){
        console.log('edit product',product)
        this.$router.push ( {
            name: 'editCatalogue',
            params: { 
            product,
            ulid: product.ulid
            }
        })
        }
    }
}
</script>

<style scoped>
img {
    height: 16rem;
}
@media screen and (max-width: 992px) {
  .computer {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .mobile {
      display: none !important
  }
}

.no-border{
    border-color:#fff !important
}
/* CRUD */
.crud.edit{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: 34px;
top: -20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.crud.delete{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: -13px;
top: -20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.card .crud {
    /* visibility: hidden; */
    display: none
}
.card:hover>.crud {
    /* visibility: visible; */
    display: inherit;
}
</style>